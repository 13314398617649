import { initializeApp } from "firebase/app";

import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, FacebookAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { firebaseConfig } from "./config"

import { startAfter, getFirestore, collection, doc, setDoc, query, getDoc, where, orderBy, getDocs, runTransaction, addDoc, deleteDoc, limit } from "firebase/firestore";

const firebase = initializeApp(firebaseConfig);


export const db = getFirestore(firebase);

export const USER_STATES = {
    NOT_LOGGED: null,
    NOT_KNOW: undefined,
}

export const fetchUser = async (id) => {
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const data = docSnap.data()
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return false
    }
}

export const addUser = async (props) => {

    function id(texto) {
        texto = texto.replace(/ñ/g, 'x')
            .replace(/-/g, "x")
        texto = texto.replace(/[^\w\s-]|_/g, 'x')
            .replace(/\s+/g, 'x')
            .replace(/&/g, 'x')
            .replace(/;/g, 'x')
        texto = texto.toUpperCase();
        texto = texto.replace(/\s+/g, 'x');
        return texto;
    }

    try {
        await setDoc(doc(db, "users", id(props.nombre)), {
            ...props,
            creeatedAt: new Date()
        })
        return true

    } catch (err) {
        console.log(err)
        return false
    }


}

