import React, { useEffect, useState } from 'react';
import Style from './BaseLayout.module.scss'
// import Navbar from "./Navbar";
import Home from "./home/Home";
// import About from "./about/About";
// import Portfolio from "./portfolio/Portfolio";
import { Route, Routes } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import AddUser from './addUser/index'

export default function BaseLayout() {
   let [darkMode, setDarkMode] = useState(true);

   function handleToggleDarkMode() {
      let oppositeOfCurrentDarkMode = !darkMode
      console.log(oppositeOfCurrentDarkMode)
      localStorage.setItem('darkMode', `${oppositeOfCurrentDarkMode}`)
      setDarkMode(oppositeOfCurrentDarkMode)
   }

   // useEffect(() => {
   //    let detectedDarkMode = eval(localStorage.getItem('darkMode'));

   //    if (detectedDarkMode) {
   //       setDarkMode(detectedDarkMode)
   //    } else {
   //       localStorage.setItem('darkMode', 'false')
   //    }
   // }, [])

   return (
      <Box className={darkMode ? Style.dark : Style.light}>
         <Grid container display={'flex'} flexDirection={'column'} minHeight={'100vh'}
            justifyContent={'space-between'}>
            {/* <Grid item>
               <Navbar darkMode={darkMode} handleClick={handleToggleDarkMode}/>
            </Grid> */}
            <Grid item flexGrow={1}>
               <Routes>
                  <Route exact path={'/:userId'} element={<Home />} />
                  <Route exact path={'/add-user'} element={<AddUser />} />
                  {/* <Route exact path={'/portfolio'} element={<Portfolio/>}/> */}
               </Routes>
            </Grid>
            <Grid item>
               <Box component={'footer'} display={'flex'} flexDirection={'column'} alignItems={'center'}
                  py={'1.5rem'} sx={{ opacity: 0.7 }} width={'100%'}>
                  {/* <p>template created with &hearts; by <a href={'https://paytonpierce.dev'}>Payton Pierce</a></p> */}
                  <p>&copy; 2023</p>
               </Box>
            </Grid>
         </Grid>
      </Box>
   )
}

