import React from 'react';
import { Box } from "@mui/material";

function EmojiBullet(props) {
    const { emoji, text, emergency, info, icon } = props;

    return (
        <Box component={'li'} fontSize={'1rem'} lineHeight={1.5} style={{ cursor: 'default', display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: '10px' }}>
            {
                emergency ? <>
                    <span style={{ background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: "24px", fontWeight: "700" }}>{emoji}</span>
                </> : <Box
                    component={'span'}
                    aria-label="cheese"
                    role="img"
                    mr={{ xs: '0.5rem', md: '1rem' }}
                    fontSize={'15px'}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '5px'
                    }}

                >
                    {icon}
                    {emoji}</Box>
            }
            <p>
                {text}
            </p>
        </Box>
    );
}

export default EmojiBullet;