import { Box, Grid } from "@mui/material";
import { width } from "@mui/system";
import { useState } from "react";
import { addUser } from "../../firebase/client";
import Button from "../Button";
import Input from "../Forms/Input";


export default function AddUser() {

    const values = {
        nombre: "",
        apellido: "",
        cedula: "",
        edad: "",
        sexo: "",

        empresa: "",
        rol: '',

        telefono: "",
        telefonoEmpresa:"",
        direccion: "",
        correo: "",
        sitioweb: "",

        nombreEmergencia: "",
        telefonoEmergencia: "",
        aseguradora: "",
    }

    const labels = {
        nombre: "Nombre",
        edad: "Edad",
        apellido: "Apellido",
        empresa: "Empresa",
        rol: "Rol en la empresa",
        sexo: "Sexo (M o F)",
        cedula: "Cedula",

        telefono: "Telefono",
        correo: "Correo",
        nombreEmergencia: "Nombre de contacto para emergencia",
        telefonoEmergencia: "Telefono de emergencia",
        aseguradora: "Aseguradora",
        direccion: "Dirección",
        sitioweb: "Sitio web",
    }

    const keys = Object.keys(values)

    const [user, setUser] = useState(values)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const handleInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handleClick = async () => {
        try {
            setSuccess(false)
            setError(false)
            setLoading(true)
            const request = await addUser(user)
            if (request) setSuccess(true)
            if (error) setError(true)
        } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    return (
        <Box component={'main'} display={'flex'} marginTop={{ md: "20px" }} flexDirection={{ xs: 'column', }} alignItems={'center'} justifyContent={'center'} minHeight={'calc(100vh - 175px)'}>
            <Box
                style={{
                    display: 'grid',
                    marginBottom: "10px",
                    gap: "5px",
                    marginTop: "2rem",
                    width: "350px"
                }}
            >
                <h2 style={{ textAlign: 'left', marginBottom: "1rem" }}>Agregar usuario</h2>
                {
                    keys.map((x, index) => <>
                        <label style={{ fontSize: "14px", fontWeight: "700" }}>{labels[x]}</label>
                        <Input style={{ display: "flex", width: "100%" }} name={x} onChange={handleInputChange} />
                    </>)
                }
                {
                    loading ? <p style={{ textAlign: "center", fontSize: "14px", marginTop: "20px", fontWeight: "700" }}>Cargando...</p> : <Button onClick={() => handleClick()}>Agregar usuario</Button>
                }

                {
                    error && <p style={{ color: 'red', textAlign: "center", fontSize: "14px", marginTop: "20px", fontWeight: "700" }}>Error en ingreso de usuario</p>
                }

                {
                    success && <p style={{ color: 'green', textAlign: "center", fontSize: "14px", marginTop: "20px", fontWeight: "700" }}>Usuario ingresado</p>
                }

            </Box>
        </Box>
    )
}