import React, { useEffect, useState } from 'react'
import Style from './Home.module.scss'
import classNames from 'classnames'
import EmojiBullet from "./EmojiBullet"
import SocialIcon from "./SocialIcon"
import { Box, CircularProgress } from "@mui/material"
import { info } from "../../info/Info"
import { useParams } from 'react-router-dom'
import { fetchUser } from '../../firebase/client'
// import DateRangeIcon from '@mui/icons-material/DateRange'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
// import BloodtypeIcon from '@mui/icons-material/Bloodtype'
// import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
// import ColorLensIcon from '@mui/icons-material/ColorLens';
// import ShareIcon from '@mui/icons-material/Share';
// import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
// import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios'

const values = {
   nombre: "",
   edad: "",
   sexo: "",
   cedula: "",
   telefono: "",
   correo: "",
   licencia: "",
   tipoLicencia: "",
   modeloParapente: '',
   colorParapente: "",
   nombreEmergencia: "",
   telefonoEmergencia: "",
   aseguradora: "",
   homologacion: "",
}

export default function Home() {

   let { userId } = useParams()
   const [user, setUser] = useState(values)
   const [loading, setLoading] = useState(true)

   const items = [
      {
         icon: <PermIdentityIcon />,
         emoji: 'Cedula',
         text: user.cedula
      },
      {
         icon: <PermIdentityIcon />,
         emoji: 'Empresa',
         text: user.empresa
      },
      {
         icon: <PermIdentityIcon />,
         emoji: 'Cargo',
         text: user.rol
      },
      {
         icon: <PhoneIphoneIcon />,
         emoji: "Telefono",
         text: user.telefono
      },
      // {
      //    emergency: true,
      //    emoji: "En caso de emergencia",
      //    text: ""
      // },
      // {
      //    emoji: "Nombre",
      //    icon: <EmergencyRecordingIcon />,
      //    text: `${user.nombreEmergencia}`
      // },
      // {
      //    emoji: "Telefono",
      //    icon: <ContactPhoneIcon />,
      //    text: `${user.telefonoEmergencia}`
      // },
      // {
      //    icon: <EmergencyShareIcon />,
      //    emoji: "Aseguradora",
      //    text: `${user.aseguradora}`
      // },
      {
         emoji: "Correo",
         icon: <EmailIcon />,
         text: `${user.correo}`
      }
   ]

   const handleDownload = async () => {

      try {
         //El servidor presentado no es el indicado para tener esta funcion
         const request = await axios.post('https://creator-bot-globalboletos-production.up.railway.app/vcard', {
            firstName: user.nombre,
            lastName: user.apellido,
            organization: user.empresa,
            url: user.sitioweb,
            cellPhone: user.telefono,
            otherPhone: user.telefonoEmpresa,
            email: user.correo,
         })
         const vcardContent = request.data
         const element = document.createElement('a');
         const file = new Blob([vcardContent], { type: 'text/vcard' });
         element.href = URL.createObjectURL(file);
         element.download = `contacto.vcf`;
         document.body.appendChild(element);
         element.click();
         document.body.removeChild(element);
      } catch (err) {
         console.log(err)
      }
   }


   const socials = [
      {
         link: `tel:${user.telefono}`,
         icon: 'fa fa-phone',
         label: 'phone',
         open: () => window.location.href = `tel:${user.telefono}`

      },
      {
         link: `https://api.whatsapp.com/send?phone=+${user.telefono}&text=Hola +${user.nombre}`,
         icon: 'fa fa-whatsapp',
         label: 'whatsapp',
         open: () => window.open(`https://api.whatsapp.com/send?phone=+${user.telefono}&text=Hola +${user.nombre}`)
      },
   ]

   const request = async () => {
      try {
         const response = await fetchUser(userId)
         if (response) {
            setUser(response)
            setLoading(false)
         }
      } catch (err) {
         console.log(err)
      }
   }

   useEffect(() => {
      request()
   }, [userId])

   return (
      <Box
         component={'main'}
         display={'flex'}
         marginTop={{ md: "20px" }}
         flexDirection={{ xs: 'column', }}
         alignItems={'center'}
         justifyContent={'center'}
         minHeight={'calc(100vh - 175px)'}
         style={{
         }}
      >
         {
            loading ? <CircularProgress style={{ marginTop: "3rem" }} /> : <>
               <Box
                  className={classNames(Style.avatar, Style.shadowed)}
                  alt={'image of developer'}
                  style={{ background: info.gradient, objectFit: "cover",marginTop:"1.5rem" }}
                  component={'img'}
                  src={'https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg'}
                  width={{ xs: '30vh', md: '30vh' }}
                  height={{ xs: '30vh', md: '30vh' }}
                  borderRadius={'50%'} p={'0.75rem'} mb={{ xs: '1rem', sm: 0 }} mr={{ xs: 0, md: '2rem' }}
               />

               <Box
                  className={classNames(Style.boxMain)}
               >
                  <h2 className={Style.textCenter}
                  >
                     {user.nombre} {user.apellido}
                     {/* <span style={{ background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{user.nombre}</span><span className={Style.hand}>🤚
                  </span> */}
                  </h2>
                  {/* <h2>{user.licencia} {user.tipoLicencia}</h2> */}
                  <Box component={'ul'} p={'0.8rem'}
                     style={{
                        maxWidth: '98vw',
                        margin: 'auto',
                     }}
                  >
                     {items.map((bio, index) => (
                        <EmojiBullet key={index} icon={bio.icon} info={info} emoji={bio.emoji} text={bio.text} emergency={bio.emergency} />
                     ))}
                  </Box>

                  {/* <Box display={'flex'}>
                     <button
                        onClick={() => handleDownload()}
                        style={{
                           width: '100%',
                           margin: '1rem',
                           height: '48px',
                           outline: 'none',
                           border: 'none',
                           borderRadius: '6px',
                           color: '#1f1f1f',
                           fontWeight: '700',
                           background: info.gradient,
                        }}
                     >Guardar contacto</button>
                  </Box> */}

                  <Box
                     display={'flex'}
                     gap={'1.5rem'}
                     justifyContent={'center'}
                     fontSize={{ xs: '2rem', md: '2.5rem' }}
                     style={{
                        width: "100%",
                        marginTop:"1.5rem" 
                     }}
                  >
                     <Box
                        style={{
                           display: "flex",
                           margin: "auto",
                           gap: "40px"
                        }}
                     >
                        {socials.map((social, index) => (
                           <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} onClick={social.open} />
                        ))}
                     </Box>
                  </Box>
               </Box>
            </>
         }
      </Box >
   )
}